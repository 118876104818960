$(function () {
	$("[data-hf-parent]").each(function () {
		var parent = $(this);
		var child = $('[data-hf-child="' + parent.attr("data-hf-parent") + '"]');
		if (child.length) {
			parent.on("change", function () {
				hierarchyFields(parent, child);
			});
			hierarchyFields(parent, child);
		}
	});
});
function hierarchyFields(parent, child) {
	var showValue = child.attr("data-hf-value") ?? true;
	var value = parent.attr("type") == "checkbox" ? parent.is(":checked") : parent.val();
	var childContainer = child.closest(".form-group");
	if (value == showValue) {
		childContainer.removeClass("d-none");
	} else {
		childContainer.addClass("d-none");
		if (child.attr("type") == "checkbox") {
			child.prop("checked", false);
		} else {
			child.val(null);
		}
	}
	var panel = child.closest(".field-form_panel");
	if (panel) {
		var showedChildrens = panel.find(".form-group:not(.d-none)");
		if (showedChildrens.length) {
			panel.removeClass("d-none");
		} else {
			panel.addClass("d-none");
		}
	}
}
